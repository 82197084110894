import * as React from "react";
import HcpLayout from "../components/hcp/layout";
import { Seo } from "../components/seo";
import ExternalLink from "../components/external-link";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import "./privacy-policy.scss";

const PrivacyPolicy = () => {
  const { externalLinkInfo } = useSiteMetadata();
  return (
    <HcpLayout pageClass="privacy-policy" noStickyHCPLocatorCTA={true}>
      <section className="privacy-policy__content inner-first">
        <div className="centered-content">
          <h1 className="font-40">Privacy Policy</h1>
          <p>
            We encourage you to read the Privacy Policy in its entirety before
            using the site. By accessing and using the site or its services, you
            agree to the collection and use of information in accordance with
            this Privacy Policy, as well as to the Terms of Use.
          </p>
          <p>
            Harmony Biosciences, ("Harmony") has provided this website and its
            content for general informational purposes only, for US residents.
            Harmony and its affiliates value your privacy and the security of
            your personal information. This notice explains our online
            information practices and the choices you can make about the way
            your information is collected and used.
          </p>
          <p>
            This Privacy Policy applies only to the use and collection of data
            collected by this website, its services, and related mobile
            applications, and does not apply to any other data collected by
            Harmony online or offline unless specifically linked to this Privacy
            Policy.
          </p>
          <p>
            Harmony operates our websites and any mobile applications that are
            owned by us. As used in this Privacy Policy, "We" includes Harmony
            and its related companies, as well as any third-party vendors we
            hire to assist in administration of the website. These vendors are
            required to maintain the confidentiality of user information in
            accordance with the terms of this Privacy Policy and to otherwise
            abide by the terms of this Privacy Policy. These vendors are
            prohibited from using the user information for any other purpose.
          </p>
          <h2 className="asap asap-bold">Information We May Collect</h2>
          <p>
            You can visit our site without providing any personally identifiable
            information, and we do not collect any personally identifiable
            information without your consent. Please note that if you choose not
            to provide personally identifiable information when it is requested,
            you may not be able to take full advantage of the functionality and
            services of this site.
          </p>
          <p>
            If personally identifiable information and{" "}
            <nobr>non-personally</nobr> identifiable information are collected
            as detailed below, we may use both types of information to create
            aggregated <nobr>(non-personally</nobr> identifiable) statistical
            information.
          </p>
          <p className="green asap font-21 asap-bold">
            Personally Identifiable Information
          </p>
          <p>
            Harmony may request personal data when you engage with certain
            functions of the site (for example, if you request more information,
            contact us directly with feedback or questions, or opt in to any of
            the site's offerings). If you do not want Harmony to collect your
            personally identifiable information, please do not provide it to us.
            Personal data includes information that identifies you as an
            individual or relates to an identifiable individual and will be
            collected only if specifically requested and provided by you. The
            personal data we request may include:
          </p>
          <ul className="small-list">
            <li>name</li>
            <li>date of birth</li>
            <li>email address</li>
            <li>home address</li>
            <li>phone number</li>
            <li>
              professional credentials and practice details (for example,
              location and specialty), including identification number if
              applicable
            </li>
            <li>
              additional information as otherwise described to you at the point
              of collection or pursuant to your consent
            </li>
          </ul>
          <p className="mt-15">
            In addition, Harmony may occasionally request and obtain sensitive
            health-related personal data about you through channels related to
            this site (for example, if you voluntarily provide information about
            your physical or mental health as part of a request for information
            or survey). By providing us with your sensitive personal data, you
            consent to us processing these data for the purposes set out in this
            Privacy Policy.
          </p>
          <p className="green asap font-21 asap-bold">
            Non-Personally Identifiable Information
          </p>
          <p>
            Harmony collects basic technical information from all users during
            visits to the site through automatic data collection tools, which
            may include cookies and other commonly used technologies. These
            tools collect certain standard information sent to the site from
            your browser, such as your browser type and language, access times,
            and the addresses of other websites you visit before and after
            visiting our site. They may also collect information about your
            Internet Protocol (IP) address, or actions taken (clicks) as you
            interact with the site. Please see below for additional detail about
            the information we collect through cookies and other commonly used
            technologies. By using the Services, you consent to our use of
            cookies and similar technologies.
          </p>
          <h2 className="asap asap-bold">How We Use Your Information </h2>
          <p>
            We use your personal data to register you for services that you have
            signed up for, to communicate with you about your use of our
            services, to respond to questions or requests, and for other
            customer service purposes. Harmony also uses the data that we
            collect from our site to operate our business, and provide and
            improve the services we offer. We will only share your personal data
            in ways that are described in this Privacy Policy.
          </p>
          <p>
            At any time, you may choose to opt out of communications from
            Harmony. See below for more information on how to opt out.
          </p>
          <h2 className="asap asap-bold">Cookies</h2>
          <p>
            Cookies are small data files that may include an anonymous unique
            identifier. Cookies are sent to your browser from our site and
            stored on your device's hard drive to collect information. These
            data allow us to store your preferences and settings, protect you
            from fraud, and analyze the performance of our services.
          </p>
          <p>
            You have the right not to provide us with any personal data when you
            visit the site and may refuse all cookies. However, if you do not
            accept cookies, you may not be able to use some portions of the
            site. Cookies stored on your hard drive can be deleted by you at any
            time through your browser settings. For more detail and information
            on how to manage cookies, see{" "}
            <ExternalLink
              externalURL="https://www.allaboutcookies.org"
              messageTitle={externalLinkInfo.TITLE}
              messageBody={externalLinkInfo.BODY}
              classNameLink={externalLinkInfo.LINK_CLASS_NAME}
              classNameOverlay={externalLinkInfo.OVERLAY_CLASS_NAME}
            >
              <span className="blue">www.allaboutcookies.org</span>
            </ExternalLink>
            .
          </p>
          <h2 className="asap asap-bold">Security</h2>
          <p>
            Your personal data are important to us. Harmony takes several steps
            to ensure the security of your personal data, but transmission of
            information over the internet and through electronic storage is not
            100% secure. While we use commercially acceptable methods to protect
            your personal data, we cannot guarantee their absolute security.
          </p>
          <h2 className="asap asap-bold">Sharing of Information</h2>
          <p className="green asap font-21 asap-bold">
            Other Parties When Required by Law or as Necessary to Protect the
            Services
          </p>
          <p>
            While the information you provide will be retained for a
            commercially reasonable time by Harmony for administrative,
            liability, and archival purposes, your personally identifying
            information is not shared with a third party, other than (1) as
            required by law, (2) to protect and defend the rights of Harmony,
            the website, or the users of the website, (3) as incident to a
            corporate sale, merger, reorganization, dissolution, bankruptcy, or
            similar event, (4) under circumstances we believe reasonably
            necessary to protect the personal safety of users of the site or its
            services, or the public, or (5) as is otherwise described in this
            Privacy Policy.
          </p>
          <p>
            We may share your information with third-party vendors that perform
            certain functions or services on our behalf (such as to host the
            site, manage databases, perform analyses, or send communications).
            These vendors are required to maintain the confidentiality of user
            information in accordance with the terms of this Privacy Policy and
            to otherwise abide by the terms of this Privacy Policy. These
            vendors are prohibited from using the user information for any other
            purpose or in any other manner.
          </p>
          <h2 className="asap asap-bold">Social Networks</h2>
          <p>
            You may choose to access or interact with social media websites
            owned by certain third parties (such as Facebook) through our site.
            When you choose to link to these websites, you are sharing
            information with these websites, you are subject to their policies
            regarding cookies and tracking, and the information you share will
            be governed by their privacy policies. Social media websites may
            also share information with us and may post information about your
            visit to our site on their pages. You may be able to modify your
            privacy settings with these third-party websites.
          </p>
          <h2 className="asap asap-bold">Third-Party Services</h2>
          <p>
            Harmony is not responsible for the privacy policies or practices of
            any third parties, including any third party operating a website or
            service to which our site links or that provides a link to our site.
            The inclusion of a link on our site to an external source does not
            imply our endorsement of the linked website or service. You should
            review the privacy policies of third-party websites so you can
            understand how they collect, use, and share your information.
          </p>
          <h2 className="asap asap-bold">California Privacy Rights</h2>
          <p>
            State law permits California residents to annually request and
            obtain information free of charge about what personal information is
            disclosed by Harmony to third parties for third-party direct
            marketing purposes in the preceding calendar year. Harmony does not
            share information that it collects with third parties for the third
            party's direct marketing purposes.
          </p>
          <h2 className="asap asap-bold">Website Use by Children</h2>
          <p>
            This site and its services are not directed toward children under 13
            years of age, nor does Harmony knowingly collect, use, or disclose
            personal information from children under the age of 13 without prior
            parental consent, except as permitted by the Children's Online
            Privacy Protection Act ("COPPA"). If you are under the age of 13,
            please do not use the site or submit any personal information to us.
            If you believe that we have unintentionally collected personal
            information about your child, you can contact us using the
            information provided below.
          </p>
          <h2 className="asap asap-bold">Opt Out</h2>
          <p>
            You may choose to stop receiving all future email communications
            from Harmony following the unsubscribe instructions on the email
            communication.
          </p>
          <h2 className="asap asap-bold">
            Contacting Harmony About Your Personal Information
          </h2>
          <p>
            To gain access to your personally identifiable information collected
            online, and to keep it accurate, complete and current, or to request
            deletion, you may contact Harmony at{" "}
            <a
              className="blue-link"
              href="mailto:privacy@harmonybiosciences.com"
              target="_blank"
              rel="noreferrer"
            >
              privacy@harmonybiosciences.com
            </a>
            . In some cases, where we are required to retain information by law
            or regulation, or to continue to manage a request that you have
            made, or to ensure that we honor your preferences, or for other
            necessary business purposes, we may not be able to delete certain
            personal information about you.
          </p>
          <p>
            Should you have questions about this policy or Harmony's information
            collection, use and disclosure practices, you may contact us via
            email at{" "}
            <a
              className="blue-link"
              href="mailto:privacy@harmonybiosciences.com"
              target="_blank"
              rel="noreferrer"
            >
              privacy@harmonybiosciences.com
            </a>
            .{" "}
          </p>
          <h2 className="asap asap-bold">Changes to This Privacy Policy</h2>
          <p>
            This Privacy Policy is effective as of May 2020, and will remain in
            effect except with regard to any changes, which will be in effect
            immediately after being posted on this page.
          </p>
          <p>
            Because privacy as it applies to the internet can rapidly change, we
            may make amendments to this Privacy Policy that affect the use of
            your personal data. We reserve the right to change the terms of this
            Privacy Policy at any time by posting revisions to the site. You
            should periodically review this Privacy Policy for changes. If you
            do not agree to the terms of this Privacy Policy or revisions to
            this Privacy Policy, please exit the site immediately.
          </p>
        </div>
      </section>
    </HcpLayout>
  );
};

export default PrivacyPolicy;

export const Head = () => (
  <Seo
    title="Privacy Policy | WAKIX® (pitolisant)"
    description="View our privacy policy here."
  />
);
